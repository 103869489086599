import { type ClassValue, clsx } from "clsx";
import { ForwardRefExoticComponent, RefAttributes, SVGProps } from "react";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export type HeroIcon = ForwardRefExoticComponent<
    Omit<SVGProps<SVGSVGElement>, "ref"> & {
        title?: string;
        titleId?: string;
    } & RefAttributes<SVGSVGElement>
>;

export const formatIsoStringDate = (
    isoStringDate: string,
    includeTime?: boolean
) => {
    return new Date(isoStringDate).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        ...(includeTime && { hour: "2-digit", minute: "2-digit" }),
    });
};

export const formatSecondsToMinutes = (numOfSeconds: number) => {
    const minutes = Math.floor(numOfSeconds / 60);
    const seconds = numOfSeconds % 60;
    const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    return formattedTime;
};

export const PRIMARY_COLOR = "#000F61";

export const isWhite = (color: string) =>
    color === "#fff" ||
    color === "#FFF" ||
    color === "#ffffff" ||
    color === "#FFFFFF";
export const isBlack = (color: string) =>
    color === "#000" || color === "#000000";

export const HOMEPAGE = "/home";

export const datadogEnabled = process.env.ENV !== "local";
