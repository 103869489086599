"use client";

import { useEffect } from "react";
import LogRocket from "logrocket";
import { HOMEPAGE } from "@/lib/utils";

export default function Error({
    error,
    reset,
}: {
    error: Error & { digest?: string };
    reset: () => void;
}) {
    useEffect(() => {
        LogRocket.captureException(error, {
            extra: {
                hint: "General Error!!!",
            },
        });
        console.error(error);
    }, [error]);

    return (
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
                <p className="text-base font-semibold text-blue-600">שגיאה</p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    משהו השתבש
                </h1>
                <p className="mt-6 text-base leading-7 text-gray-600">
                    מתנצלים, אירעה שגיאה. אפשר לנסות שוב או לעבור למסך הראשי.
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <button
                        onClick={() => {
                            reset();
                        }}
                        className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                        נסו שוב
                    </button>
                    <a
                        href={HOMEPAGE}
                        className="text-sm font-semibold text-gray-900"
                    >
                        לעמוד הראשי<span aria-hidden="true">&larr;</span>
                    </a>
                </div>
            </div>
        </main>
    );
}
